<mat-dialog-content>
    <div class="dialog-head">
        <h2 class="mat-h2">Batteriegesundheit setzen</h2>
        <mat-icon class="close-icon" (click)="close()">close</mat-icon>
        <h4>
            <app-vehicle-link [vehicle]="dialogData.vehicle"></app-vehicle-link>
        </h4>
    </div>
    <div style="width: 500px">
        <mat-form-field appearance="fill" style="width: 450px;">
            <mat-label>Soh</mat-label>
            <input matInput type='number' min="0" step="1" max="100" [formControl]="fc">
            <mat-error *ngIf="fc.errors?.required as error">{{ error }}</mat-error>
            <mat-hint>Wird ignoriert, sobald ein echter Messwert der Batteriegesundheit vorliegt</mat-hint>
        </mat-form-field>
        <div *ngIf="resultText?.length > 0">
            <hr>
            <h3>Ergebnis:</h3>
            <div class="result">
                <span [innerHTML]="resultText"></span>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="d-flex flex-row justify-content-end gap-3">
    <button
        mat-raised-button color="primary"
        type="submit"
        (click)="save()"
    >
        Setzen
    </button>
    <button
        mat-raised-button color="accent"
        type="submit"
        (click)="close()"
    >
        Dialog schließen
    </button>
</mat-dialog-actions>




