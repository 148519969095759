import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {
    Basis,
    ChargePlanValue,
    ChargingSessionList, DataValueString,
    Evse,
    NotificationEntry,
    ReservationInstance,
    Vehicle,
    VehicleTeaser
} from '@io-elon-common/frontend-api';
import {ReservationService} from '../../../reservations/service/reservation.service';
import {DatePipe} from '@angular/common';
import {AgePipe} from '../../../../shared/helper/age.pipe';
import {DialogService} from '../../../../services/dialog.service';
import {NotificationService} from '../../../../services/api-handlers/notification.service';
import {TeslaApiDatasourceService} from '../../../tesla/service/tesla-api-datasource.service';
import {ToastrService} from 'ngx-toastr';
import {VehicleService} from '../../service/vehicle.service';
import {AbstractTableComponent} from '../../../../shared/components/tables/AbstractTableComponent';
import {EvseService} from '../../../evse/service/evse.service';
import {MatPaginator} from '@angular/material/paginator';
import {SystemService} from 'src/app/services/api-handlers/system.service';
import {LocalStorageField, localStorageGet, localStorageSave} from '../../../../shared/helper/typed-local-storage';
import {ApiHandler} from "../../../../services/api-handlers/api-handler";
import {FleetService} from "../../service/fleet.service";
import {Sort} from '@angular/material/sort';
import {AuthService} from "../../../../shared/guards/auth.service";
import {LivedataService} from "../../../../services/api-handlers/livedata.service";

const MIN = 1000 * 60;
const HOUR = MIN * 60;
const DAY = HOUR * 24;

export const OUTDATED_TIME = 3 * MIN;

interface VehiclePwr {
    vehicle: VehicleTeaser,
    live: string,
    target: string,
    unit: string
}

@Component({
    selector: 'app-vehicle-table',
    templateUrl: './vehicle-table.component.html',
    styleUrls: ['./vehicle-table.component.scss']
})

export class VehicleTableComponent extends AbstractTableComponent implements OnInit, OnChanges {
    public readonly Infinity = Infinity;

    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

    @Input() reservations!: ReservationInstance[];
    @Input() vehicles!: VehicleTeaser[];
    @Input() evses!: Evse[];
    @Input() activeChargingSessions!: ChargingSessionList;
    @Input() basis!: Basis;
    @Input() fleetId!: number;
    public activeNotifications: NotificationEntry[] = [];
    public mutedNotifications: NotificationEntry[] = [];
    public isDev = false;
    private isUserSort: boolean = false;
    displayedColumns: string[] = [
        'icon',
        'name',
        'plug',
        'evse',
        'sourceDesc',
        'stateOfCharge',
        'range',
        'soh',
        'algoData',
        'status',
        'actions'
    ];

    dataSource = new MatTableDataSource<VehicleTeaser>([]);
    selection = new SelectionModel<VehicleTeaser>(true, []);
    selectedIndex = 0;
    displayStopChargeButton: boolean = false;
    vehiclePwrArray: VehiclePwr[] = [];
    sort: Sort = {
        active: localStorageGet("FLEET_SORT_COLUMN", 'name'),
        direction: localStorageGet("FLEET_SORT_ORDER", 'asc'),
    };

    constructor(
        private readonly reservationService: ReservationService,
        private readonly datePipe: DatePipe,
        private readonly agePipe: AgePipe,
        private readonly dialogService: DialogService,
        private readonly notificationService: NotificationService,
        private readonly teslaDatasourceService: TeslaApiDatasourceService,
        private readonly toastr: ToastrService,
        private readonly vehicleService: VehicleService,
        private readonly evseService: EvseService,
        private readonly systemService: SystemService,
        private readonly fleetService: FleetService,
        private readonly authService: AuthService,
        private readonly liveDataService: LivedataService
    ) {
        super();
    }

    public async ngOnInit(): Promise<void> {
        this.isDev = this.authService.isDeveloper();
        this.notificationService.getActiveNotifications().subscribe(x => {
            if (x == undefined)
                return;
            this.activeNotifications = x.activeNotifications.sort((n1, n2) => n1.id - n2.id);
            this.mutedNotifications = x.mutedNotifications.sort((n1, n2) => n1.id - n2.id);
        });
        this.dataSource = new MatTableDataSource<VehicleTeaser>(this.vehicles);
        this.dataSource.paginator = this.paginator;
        this.dataSource.data = [];

        this.displayStopChargeButton = (await this.systemService.getSystemInfo()).displayStopChargeButton;
    }

    public ngOnChanges(changes: SimpleChanges) {
        this.vehicles.forEach(v => this.initPwrMsg(v));
        if (this.isUserSort) {
            this.sortData(this.vehicles);
        }
        this.dataSource.data = this.vehicles;
    }

    public canViewEvse(evse: Evse): boolean {
        // TODO replace with cnaBeViewedBy of evse
        return this.authService.hasGlobalPermission("VIEW_VEHICLES")
            || this.authService.hasFleetPermission("VIEW_VEHICLES_OF_FLEET", this.fleetId);
    }
    public trackById(idx: number, item:{id: number}) {
        return item.id;
    }

    public selectRow(row: VehicleTeaser): void {
        this.selectedIndex = row.id;
    }

    public getChargePlanInfo(row: VehicleTeaser): ChargePlanValue | undefined {
        return row.liveData.currentPlan;
    }

    public async openBookingDialog(vehicle: VehicleTeaser): Promise<void> {
        if (vehicle.canAddReservation) {
            await this.reservationService.showNewDialog({ vehicle, vehicles: this.vehicles })
        } else {
            await this.dialogService.showInfoDialog("Info", "Sie haben keine Berechtigungen um dieses Fahrzeug zu buchen");
        }
    }

    public getBattery(vehicle: VehicleTeaser): string {
        if (vehicle?.liveData?.estimatedSocU !== undefined) {
            return vehicle.liveData.estimatedSocU?.val.toFixed(0);
        }
        return "--";
    }

    public getBatteryTarget(vehicle: VehicleTeaser): string {
        if (vehicle?.liveData?.targetSocU !== null) {
            return " >> " + vehicle.liveData.targetSocU?.toFixed(0) + "%";
        }
        return "";
    }

    public isOutdated(vehicle: VehicleTeaser): boolean {
        if (!vehicle.dataSource) {
            return true;
        }
        return vehicle.liveData.lastPackageTst < Date.now() - OUTDATED_TIME;
    }

    public getAge(vehicle: VehicleTeaser): string {
        const connectTst = vehicle.liveData.lastConnectionTst ?? 0;
        const disconnectTst = vehicle.liveData.lastDisconnectionTst?? 0;
        const packetTst = vehicle.liveData.lastPackageTst?? 0;
        const events: DataValueString[] = vehicle.liveData.vehicleLastApiEvents?? [];

        if (connectTst == 0) { //Polling Actor
            if(events.length>0) {
                const event = events.reduce((acc, event)=> acc.tst>event.tst? acc: event);
                if (event.tst>packetTst) {
                    switch (event.val) {
                        case "Mercedes.RegisteredVin":
                            return "🟡 Offline (Wartet auf Fahrzeug registration)";
                        default:
                            break;
                    }
                }
            }

            if (this.isOutdated(vehicle)) {
                return "🔴 Letzte Daten: " + this.agePipe.transform(packetTst, undefined, true, false);
            } else {
                return "🟢 Aktuell"
            }
        } else {
            if (disconnectTst > connectTst && packetTst < disconnectTst) {
                return "🔴 Offline (Seit " + this.agePipe.transform(disconnectTst, undefined, false) + ")";
            } else {
                if(events.length>0) {
                    const event = events.reduce((acc, event)=> acc.tst>event.tst? acc: event);
                    if (event.tst>packetTst) {
                        switch (event.val) {
                            case "Mercedes.RegisteredVin":
                                return "🟡 Offline (Wartet auf Fahrzeug registration)";
                            default:
                                break;
                        }
                    }
                }
                if (packetTst > connectTst) {
                    if (this.isOutdated(vehicle)) {
                        return "🟡 Online (Letzte Daten " + this.agePipe.transform(packetTst, undefined, true, false) + ")";
                    } else {
                        return "🟢 Online (Daten aktuell)"
                    }
                } else {
                    return "🟡 Online ohne Daten (Seit "+this.agePipe.transform(connectTst, undefined, false)+")";
                }
            }
        }
    }

    public getAgeDetails(vehicle: VehicleTeaser): string {
        const connectTst = vehicle.liveData.lastConnectionTst ?? 0;
        const disconnectTst = vehicle.liveData.lastDisconnectionTst ?? 0;
        const packetTst = vehicle.liveData.lastPackageTst ?? 0;
        let details = "Letzte Daten: "+this.agePipe.transform(packetTst, undefined, true, false);
        if (connectTst != 0) {
            details+="\nLetzter Verbindungsaufbau: "+this.agePipe.transform(connectTst, undefined, true, false);
        }
        if (disconnectTst != 0) {
            details+="\nLetzter Verbindungsabbruch: "+this.agePipe.transform(disconnectTst, undefined, true, false);
        }
        vehicle.liveData.vehicleLastApiEvents?.forEach((apiEvent: DataValueString) => {
            details+="\n"+apiEvent.val+": "+this.agePipe.transform(apiEvent.tst, undefined, true, false);
        });
        return details;
    }

    public getReservations(vehicle: VehicleTeaser): ReservationInstance[] {
        return this.reservations.filter(r => r.end > Date.now() && r.reservation.vehicle.id === vehicle.id);
    }

    public getReservationStatus(vehicle: VehicleTeaser): {
        msg: string,
        msg2: string,
        color: string
    } {
        const reservation = this.getReservations(vehicle).sort((r1, r2) => r1.start - r2.start)[0];
        if (!reservation) {
            return {
                msg: "Frei",
                msg2: "",
                color: getComputedStyle(document.body).getPropertyValue("--blue-1")
            };
        }
        if (reservation.start < Date.now()) {
            if (reservation.end - Date.now() > DAY) {
                return {
                    msg: 'Gebucht',
                    msg2: "bis " + this.datePipe.transform(reservation.end, "dd.MM.") + ' ' +
                        this.datePipe.transform(reservation.end, 'shortTime'),
                    color: "inherit"
                };
            } else {
                return {
                    msg: 'Gebucht',
                    msg2: "bis " + this.datePipe.transform(reservation.end, 'shortTime'),
                    color: "inherit"
                };
            }
        }
        if (reservation.start - Date.now() < DAY) {
            return {
                msg: 'Gebucht',
                msg2: "ab " + this.datePipe.transform(reservation.start, 'shortTime') + "",
                color: "inherit"
            };
        }
        return {
            msg: "Gebucht",
            msg2: "am " + this.datePipe.transform(reservation.end, "dd.MM.") + "",
            color: getComputedStyle(document.body).getPropertyValue("--blue-1")
        };
    }

    public hasTronityButtons(vehicle: VehicleTeaser): boolean {
        return !!vehicle.dataSource?.name.startsWith("Tronity") && vehicle.canEdit
    }

    public async tronityConnect(vehicle: VehicleTeaser): Promise<void> {
        if(vehicle.dataSource?.name.startsWith("Tronity")) {
            document.cookie = "vehicleId=" + vehicle.id;
            if(this.fleetService.selectedFleet.getValue()) {
                document.cookie = "fleetId=" + vehicle.id;
            } else {
                document.cookie = "fleetId=; expires=Thu, 01 Jan 1970 00:00:00 UTC;"
            }

            if(ApiHandler.customerId) {
                document.cookie = "customerId=" + ApiHandler.customerId
            } else {
                document.cookie = "customerId=; expires=Thu, 01 Jan 1970 00:00:00 UTC;"
            }
            window.open(this.systemService.getSystemInfoSync()?.tronityUrl);
            this.toastr.warning("Navigiere zu Tronity zum Verbinden");
        } else {
            this.toastr.warning("Das Fahrzeug ist dafür nicht geeignet");
        }
    }

    public async edit(vehicle: Vehicle): Promise<void> {
        await this.vehicleService.showEditDialog(vehicle);
    }

    public canStop(vehicle: Vehicle): boolean {
        return this.displayStopChargeButton && this.activeChargingSessions.list.filter(s => s.vehicleId === vehicle.id).length > 0
    }

    public isStopped(vehicle: Vehicle): boolean {
        const chargingSessions = this.activeChargingSessions.list.filter(s => s.vehicleId === vehicle.id);
        return chargingSessions.length > 0 && chargingSessions[0].state === "stopped"
    }

    public async stop(vehicle: Vehicle): Promise<void> {
        const chargingSessions = this.activeChargingSessions.list.filter(s => s.vehicleId === vehicle.id);
        const evseId = chargingSessions[0].evseId;
        try {
            const response = await this.evseService.stopCharging(evseId);
            if(response.success) {
                this.toastr.info("Ladevorgang wird gestoppt");
            } else {
                console.error(response.result);
                this.toastr.warning("Fehler beim stoppen des Ladevorgangs: " + response.result);
            }
        } catch (exc) {
            this.toastr.warning("Fehler beim stoppen des Ladevorgangs");
            console.error(exc);
        }
    }

    public async continue(veh: Vehicle) {
        const chargingSessions = this.activeChargingSessions.list.filter(s => s.evseId === veh.evse?.id);
        const evseId = chargingSessions[0].evseId;
        try {
            const response = await this.evseService.continueCharging(evseId);
            if(response.success) {
                this.toastr.info("Ladevorgang wird fortgesetzt.");
            } else {
                console.error(response.result);
                this.toastr.warning("Fehler beim Fortsetzen des Ladevorgangs: " + response.result);
            }
        } catch (exc) {
            this.toastr.warning("Fehler beim Fortsetzen des Ladevorgangs");
            console.error(exc);
        }
    }

    public isTimeInPast(targetSocReachedTime: number): boolean {
        return Date.now() > targetSocReachedTime;
    }

    public isEstimatedSocUTargetSocUClose(vehicle: VehicleTeaser): boolean {
        if (vehicle?.liveData?.estimatedSocU !== undefined && vehicle?.liveData?.targetSocU !== undefined) {
            const estimatedSocU = vehicle.liveData.estimatedSocU.val;
            const targetSocU = vehicle.liveData.targetSocU;
            return estimatedSocU >= targetSocU || (Math.abs(targetSocU - estimatedSocU) * 100) / targetSocU <= 1
        }
        return false;
    }

    public getPwrMsg(vehicle: VehicleTeaser): VehiclePwr | undefined {
        return this.vehiclePwrArray.find(v => v.vehicle === vehicle);
    }

    private calcPwr(pwr: number | undefined, amps: number | undefined, voltage: number | undefined): number | undefined {
        if(pwr != undefined) {
            return pwr;
        }
        if(amps == undefined) {
            return undefined;
        }
        return amps * (voltage || 235);
    }

    public toggleTargetVisible() {
        localStorageSave("TARGET_VISIBLE", localStorageGet("TARGET_VISIBLE", "false") === "true" ? "false" : "true")
    }

    get targetVisible(): boolean {
        return localStorageGet("TARGET_VISIBLE", "false") === "true";
    }

    public setSort(sort: Sort) {
        this.isUserSort = true;
        this.sort = sort;
        this.sortData(this.dataSource.data);
        localStorageSave("FLEET_SORT_COLUMN", sort.active as LocalStorageField["FLEET_SORT_COLUMN"]);
        localStorageSave("FLEET_SORT_ORDER", sort.direction as LocalStorageField["FLEET_SORT_ORDER"]);
    }

    private compareNumValues(value1: number | undefined, value2: number | undefined, order: boolean): number {
        if ((value1 === undefined || isNaN(value1)) && (value2 === undefined || isNaN(value2))) {
            return 0;
        }

        if (order) {
            if (value1 === undefined || isNaN(value1)) {
                return 1;
            }
            if (value2 === undefined || isNaN(value2)) {
                return -1;
            }

            if (value1 > value2) {
                return -1;
            }
            if (value1 < value2) {
                return 1;
            }
        } else {
            if (value1 === undefined || isNaN(value1)) {
                return -1;
            }
            if (value2 === undefined || isNaN(value2)) {
                return 1;
            }
            if (value1 < value2) {
                return -1;
            }
            if (value1 > value2) {
                return 1;
            }
        }
        return 0;
    }

    private compareStringValues(value1: string | undefined, value2: string | undefined, order: boolean): number {
        if (value1 === undefined && value2 === undefined) {
            return 0;
        }

        if (order) {
            if (value1 === undefined) {
                return 1;
            }
            if (value2 === undefined) {
                return -1;
            }
            return value2.localeCompare(value1);
        } else {
            if (value1 === undefined) {
                return -1;
            }
            if (value2 === undefined) {
                return 1;
            }
            return value1.localeCompare(value2);
        }
    }

    private sortData(data: VehicleTeaser[]) {
        const isdesc = this.sort.direction === 'desc';
        switch (this.sort.active) {
            case "name":
                data.sort((a, b) => this.compareStringValues(a.name, b.name, isdesc));
                return;
            case "plugIcon":
                data.sort((a, b) => {
                        const vehicleA = parseFloat(<string> this.getPwrMsg(a)?.live);
                        const vehicleB = parseFloat(<string> this.getPwrMsg(b)?.live);
                        return this.compareNumValues(vehicleA, vehicleB, isdesc);
                    }
                );
                return;
            case "evse" :
                data.sort((a, b) => this.compareStringValues(a.evse?.name, b.evse?.name, isdesc));
                return;
            case "sourceDesc" :
                data.sort((a, b) => this.compareStringValues(a.dataSource?.displayName, b.dataSource?.displayName, isdesc));
                return;
            case "stateOfCharge" :
                data.sort((a, b) =>
                    {
                        const vehicleA = a.liveData.estimatedSocU?.val;
                        const vehicleB = b.liveData.estimatedSocU?.val;
                        return this.compareNumValues(vehicleA, vehicleB, isdesc);
                    }
                );
                return;
            case "range": data.sort((a, b) =>
                {
                    const vehicleA = a.liveData.rangeNow?.val;
                    const vehicleB = b.liveData.rangeNow?.val;
                    return this.compareNumValues(vehicleA, vehicleB, isdesc);
                }
            );
            return;
            case "soh": data.sort((a, b) =>
                {
                    const vehicleA = a.liveData.soh?.val;
                    const vehicleB = b.liveData.soh?.val;
                    return this.compareNumValues(vehicleA, vehicleB, isdesc);
                }
            );
            return;
        }
    }

    private initPwrMsg(vehicle: VehicleTeaser) {
        if (!vehicle.liveData.charging?.val || (vehicle.liveData.plugged !== undefined && !vehicle.liveData.plugged.val)) {
            return;
        }

        const target = vehicle.liveData.iCp?.val;

        const p1 = this.calcPwr(vehicle.liveData.p1?.val, vehicle.liveData.i1?.val, vehicle.liveData.u1?.val);
        const p2 = this.calcPwr(vehicle.liveData.p2?.val, vehicle.liveData.i2?.val, vehicle.liveData.u2?.val);
        const p3 = this.calcPwr(vehicle.liveData.p3?.val, vehicle.liveData.i3?.val, vehicle.liveData.u3?.val);

        if(p1 === undefined) {
            this.vehiclePwrArray.push({
                vehicle: vehicle,
                live: "-",
                target: target? target + " A": "-",
                unit: ""
            });
            return;
        }

        const p = p1 + (p2 || 0) + (p3 || 0);

        this.vehiclePwrArray.push({
            vehicle: vehicle,
            live: (p / 1000).toFixed(1),
            target: target? (target * 3 * 235 /1000).toFixed(1) : "-",
            unit: "kW"
        });
    }

    public async assign(veh?: Vehicle, evse?: Evse) {
        try {
            await this.liveDataService.assign(evse?.id, veh?.id);
            this.toastr.success("Assignment erledigt.")
        } catch (e) {
            this.toastr.error("Fehler beim Assignment")
        }
    }
}
